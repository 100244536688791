<template>
  <div class="container">
    <div class="chart-container">
      <canvas :id="'id_' + chartId"></canvas>
    <div class="circle1"></div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  props: {
    chartId: {
      type: String,
      default: 'myChart'
    },
    data: {
      type: Array,
      default () {
        return [2, 4, 3, 2, 4, 4, 2]
      }
    },
    labels: {
      type: Array,
      default () {
        return ['spørgsmål 1', 'spørgsmål 2', 'spørgsmål 3', 'spørgsmål 4', 'spørgsmål 5', 'spørgsmål 6', 'spørgsmål 7']
      }
    },
    bgColors: {
      type: Array,
      default () {
        return ['#DA6457', '#74AC97', '#F5C95F', '#DA6457', '#74AC97', '#74AC97', '#DA6457']
      }
    }
  },
  data () {
    return {
      myChart: '',
      datasets: []
    }
  },
  methods: {
    createChart (chartId, chartData) {
      const ctx = document.getElementById(chartId)
      this.myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      })
    },
    createDatasets (data, labels, bgColors) {
      const innerCircle = {}
      innerCircle.data = []
      data.forEach(() => { innerCircle.data.push(1) })
      innerCircle.backgroundColor = '#fff'

      const background = {}
      background.data = []
      data.forEach(() => { background.data.push(4) })
      background.backgroundColor = 'rgba(0,0,0,.03)'
      background.labels = labels

      const newDataset = {}
      newDataset.data = data
      newDataset.backgroundColor = []
      data.forEach(score => {
        const scoreColor = score === 2 ? '#DA6457' : (score === 3 ? '#F5C95F' : '#74AC97')
        newDataset.backgroundColor.push(scoreColor)
      })

      return [innerCircle, newDataset, background]
    },
    updateChart () {
      this.myChart.data.datasets = this.createDatasets(this.data, this.labels, this.bgColors)
      this.myChart.update()
    }
  },
  watch: {
    data (newValue, oldValue) {
      this.updateChart()
    }
  },
  mounted () {
    const datasets = this.createDatasets(this.data, this.labels, this.bgColors)
    const chartId = 'id_' + this.chartId
    this.createChart(chartId, {
      type: 'polarArea',
      data: {
        datasets: datasets
      },
      // Configuration options go here
      options: {
        datasets: {
          polarArea: {
            borderColor: '#fff',
            borderWidth: '4'
          }
        },
        aspectRatio: 1,
        legend: {
          display: false
        },
        scale: {
          gridLines: {
            color: '#fdfcf8',
            lineWidth: 4,
            z: 1,
            weight: 1
          },
          ticks: {
            display: false,
            stepSize: 1
          }
        },
        tooltips: {
          displayColors: false,
          filter: function (tooltipItem, data) {
            // console.log(data.datasets[tooltipItem.datasetIndex])
            const bgColor = data.datasets[tooltipItem.datasetIndex].backgroundColor
            if (bgColor === 'rgba(0,0,0,.03)') {
              return true
            } else {
              return false
            }
          },
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex]
              var index = tooltipItem.index
              return dataset.labels[index]
            }
          },
          // Disable the on-canvas tooltip
          enabled: false,
          custom: function (tooltipModel) {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip')

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div')
              tooltipEl.id = 'chartjs-tooltip'
              tooltipEl.innerHTML = '<table></table>'
              tooltipEl.style.backgroundColor = '#FFFFFF'
              tooltipEl.style.borderColor = '#000000'
              tooltipEl.style.borderWidth = 'thin'
              tooltipEl.style.borderStyle = 'solid'
              document.body.appendChild(tooltipEl)
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0
              return
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform')
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign)
            } else {
              tooltipEl.classList.add('no-transform')
            }

            function getBody (bodyItem) {
              return bodyItem.lines
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || []
              var bodyLines = tooltipModel.body.map(getBody)

              var innerHtml = '<thead>'

              titleLines.forEach(function (title) {
                innerHtml += '<tr><th>' + title + '</th></tr>'
              })
              innerHtml += '</thead><tbody>'

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i]
                var style = 'background:' + colors.backgroundColor
                style += '; border-color:' + colors.borderColor
                style += '; border-width: 2px'
                var span = '<span style="' + style + '"></span>'
                innerHtml += '<tr><td>' + span + body + '</td></tr>'
              })
              innerHtml += '</tbody>'

              var tableRoot = tooltipEl.querySelector('table')
              tableRoot.innerHTML = innerHtml
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect()

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
            tooltipEl.style.pointerEvents = 'none'
          }
        }
      }
    })
  }
}
</script>

<style lang="stylus" scoped>
.container {
  position: relative;
  display: inline-block;
  margin: 0;
  border: none;
  border-radius: 50%;
}
</style>
