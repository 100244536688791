<template>
<div class="wrap">
  <div class="select">
    <select class="select-text" required :disabled="disabled" v-model="selected">
      <option
        v-for="option in options"
        :key="option"
        :value="option"
        :selected="selected === option"
      >{{option}}</option>
    </select>
    <label class="select-label">{{label}}</label>
    <!-- <div class="info-icon" @mouseenter="tooltip = true" @mouseleave="tooltip= false">?
      <div class="info-tooltip" v-if="tooltip && tooltipText">{{tooltipText}}</div>
    </div> -->
  </div>
</div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Vælg'
    },
    value: {
      type: String,
      default: 'Alle'
    },
    options: {
      type: Array,
      default () {
        return ['one', 'two', 'three']
      }
    },
    tooltipText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  data () {
    return {
      tooltip: false
    }
  },
  computed: {
    selected: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

/* select starting stylings ------------------------------*/
.select {
  font-family: 'Roboto','Helvetica','Arial',sans-serif;
  position: relative;
  width: 350px;
}

.info-icon {
  position: absolute;
  left: 233px;
  top: -7px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  background: lightgrey;
}
.info-tooltip {
  position: absolute;
  left: -90px;
  top: -120px;
  background: white;
  border: 1px solid black;
  font-size: 13px;
  padding: 5px;
  width: 200px;
}

.select-text {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 350px;
  padding: 10px 10px 10px 10px;
  font-size: 18px;
  border-radius: 4px;
  border: 2px solid rgba(0,0,0, 0.12);
}

/* Remove focus */
.select-text:focus {
  outline: none;
  border: 2px solid #2a6889;
}

/* Use custom arrow */
.select .select-text {
  appearance: none;
  -webkit-appearance:none;
  color: gray;
}

.select:after {
  position: absolute;
  top: 18px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.12);
  pointer-events: none;
}

/* LABEL ======================================= */
.select-label {
  color: rgb(237, 118, 94);
  background: white;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  padding: 0 5px;
  left: 10px;
  top: 12px;
  transition: 0.2s ease all;
}

/* active state */
.select-text:focus ~ .select-label, .select-text:valid ~ .select-label {
  color: rgb(237, 118, 94);
  top: -8px;
  transition: 0.2s ease all;
  font-size: 14px;
}

.select-text:valid:not(focus) ~ .select-label {
  color: rgb(237, 118, 94);
  // background: red;
}
.select-text:valid:focus ~ .select-label {
  color: #2a6889;
}
</style>
