<template>
  <div v-if="loading" id="content-area" class="align-center">
    <h1>{{loadingMessage}}</h1>
  </div>
  <div v-else id="content-area">
    <h1 class="align-center">Rapport<span> {{ type === 'personlig' ? '' : ' (' + numOfResponses + ' svar)'}}</span></h1>
    <div class="align-left">
      <div class="horizontal-flex align-center">
        <base-ui-select
          v-if="type === 'virksomhed'"
          :label="'Vælg måling du ønsker rapport for'"
          :value="responseScope"
          :options="responseScopes"
          :tooltipText="responseScopesInfoText"
          @input="setResponseScope"
        ></base-ui-select>
        <!-- <base-ui-button outline :color="'#808080'" v-if="type == 'personlig'" @click="$router.push(`/del`)">Send spørgeskemaet ud</base-ui-button> -->
        <!-- <base-ui-button outline :color="'#808080'" v-if="!$store.state.user.loggedIn && type == 'personlig'" @click="$router.push('/')">Tilbage til forside</base-ui-button> -->
        <base-ui-button v-if="$store.state.user.loggedIn" outline :color="'#808080'" @click="$router.push('/')">Tilbage til kulturmåling</base-ui-button>
        <base-ui-button :disabled="!scopedResponses[0]" outline :color="'#808080'" class="downloadButton" @click="downloadPdf">{{downloadButtonTxt}}</base-ui-button>
      </div>
      <div v-if="scopedResponses.length > 0">
        <section class="calculation-info">
          <h5
              class="expander-header"
              :class="{expanded: infoExpanded}"
              @click="infoExpanded = !infoExpanded"
            >
            Sådan læser du rapporten
            <span :class="infoExpanded ? 'chevron bottom': 'chevron top'"></span>
          </h5>
          <div v-if="infoExpanded">
            <p class="text-size-14">
              {{ info.intro }}
            </p>
            <p class="text-size-14">
              <span class="dot green"></span>
              {{info.green}}
            </p>
            <p class="text-size-14"><span class="dot yellow"></span>
              {{info.yellow}}
            </p>
            <p class="text-size-14"><span class="dot red"></span>
              {{info.red}}
            </p>
            <p class="text-size-14">
              {{ info.outtro }}
            </p>
          </div>
        </section>
        <section v-for="(cat, catIndex) in report.categories" :key="cat.title" :class="`cat-${catIndex}`">
          <h3 :style="`color: ${cat.color};`">{{cat.title}}</h3>
          <result-polar-chart
            class="category-chart float-right"
            :class="`cat-chart-${catIndex}`"
            :chartId="cat.title"
            :data="cat.scores"
            :labels="getLabels(cat.questions)"
          ></result-polar-chart>
          <p class="manchet">{{cat.description}}</p>
          <!-- <h4
            class="expander-header"
            :class="{expanded: cat.recommendationsExpanded}"
            @click="cat.recommendationsExpanded = !cat.recommendationsExpanded"
          > -->
          <h4
            class="expander-header"
            :class="{expanded: cat.recommendationsExpanded}"
          >
            Anbefaling
            <!-- <span :class="cat.recommendationsExpanded ? 'chevron bottom': 'chevron top'"></span> -->
          </h4>
          <p
            class="expander-box text-size-16"
            style="margin: 0;"
            :class="{expanded: cat.recommendationsExpanded}"
          >
            <em>{{resultInfo[catIndex].intro}}</em>
          </p>
          <div
            class="expander-box result-colors"
            :class="{expanded: cat.recommendationsExpanded}"
          >
            <h5>{{resultInfo[catIndex].recommendationsTitle}}</h5>
            <div class="recommendations">
              <div v-for="recommendation in resultInfo[catIndex].recommendations" :key="recommendation.id" class="recommendation">
                <a :href="recommendation.url" target="_blank" ><header><img :src="coreCultureIconUrl" alt="" width="16" height="16"><h6>{{recommendation.title}}</h6></header></a>
              </div>
            </div>
          </div>
          <h4
            class="expander-header"
            :class="{expanded: cat.questionsExpanded}"
            @click="cat.questionsExpanded = !cat.questionsExpanded"
          >Resultat fordelt på spørgsmål<span :class="cat.questionsExpanded ? 'chevron bottom': 'chevron top'"></span></h4>
          <div v-for="(q, i) in cat.questions" :key="q.id" class="expander-box" :class="{expanded: cat.questionsExpanded}">
            <p>{{i + 1}}. {{ getQuestionDescription(q.id) }}</p>
            <div class="question-results horizontal-flex">
              <table>
                <tr class="text-size-sm">
                  <td>I meget høj grad</td>
                  <td>I høj grad</td>
                  <td>I nogen grad</td>
                  <td>I ringe grad</td>
                  <td>I meget ringe grad</td>
                </tr>
                <tr>
                  <td>{{q.results[5]}}%</td>
                  <td>{{q.results[4]}}%</td>
                  <td>{{q.results[3]}}%</td>
                  <td>{{q.results[2]}}%</td>
                  <td>{{q.results[1]}}%</td>
                </tr>
              </table>
              <result-polar-chart
                class="question-chart"
                :class="`question-chart-${i}`"
                :chartId="q.id"
                :data="q.scores"
              ></result-polar-chart>
            </div>
          </div>
        </section>
        <!-- <div class="calculation-info">
          <h5><em>Sådan beregnes resultatet</em></h5>
          <p class="text-size-sm"><em>Besvarelsen bliver grøn, hvis 80 % eller derover har svaret ‘i meget høj grad’ eller ‘i høj grad’. Besvarelsen bliver gul, hvis under 80 % har svaret ‘i meget høj grad’ eller ‘i høj grad’, og under 20% har svaret ‘i ringe grad ’ eller ‘i meget ringe grad’. Besvarelsen bliver rød hvis 20 % eller derover af respondenterne har svaret ‘i ringe grad’ eller ‘i meget ringe grad’.</em></p>
        </div> -->
        <div v-if="type === 'personlig'" class="next-steps">
          <h3>Hvad nu?</h3>
          <div class="next-steps-columns">
            <div>
              <base-ui-button primary @click="$router.push('/del')">Start kulturmåling</base-ui-button>
              <p class="text-size-sm">Mål arbejdsfællesskabet i jeres virksomhed. Klik på ‘Start kulturmåling’ for at oprette en gratis profil og få et link, I nemt kan sende ud til medarbejderne i virksomheden.</p>
            </div>
            <!-- <div>
              <base-ui-button @click="$router.push('/login?redirect=/rapport/personlig')">Gem rapport</base-ui-button>
              <p class="text-size-sm">Hvis du logger ind / opretter en profil gemmes denne rapport automatisk og du kan genfinde den under "Personlig rapport"</p>
            </div> -->
            <div>
              <base-ui-button @click="downloadPdf">{{downloadButtonTxt}}</base-ui-button>
              <p class="text-size-sm">Konvertér rapporten til et pdf dokument som du kan printe eller gemme på din computer</p>
            </div>
            <div>
              <base-ui-button @click="$router.push('/')">Til forside</base-ui-button>
              <p class="text-size-sm"></p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h3>Der er endnu ikke registreret nogen besvarelser.</h3>
        <p>Hvis du endnu ikke har sendt et spørgeskema ud til dine kolleger, kan du hente et link <router-link to="/del">her</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
import { createSurveyReport } from '../sharedFunctions/calculations'
import { htmEl } from '../sharedFunctions/helpers'
import firebase from 'firebase/app'
import 'firebase/firestore'
import BaseUiButton from '../components/shared/BaseUiButton.vue'
import ResultPolarChart from '../components/report/ResultPolarChart.vue'
import BaseUiSelect from '../components/shared/BaseUiSelect.vue'
import html2canvas from 'html2canvas'
import html2pdf from 'html-to-pdf-js'
import coreCultureIconUrl from '../assets/imgs/core-culture-icon.png'
import { nextTick } from 'vue'

export default {
  components: { BaseUiButton, ResultPolarChart, BaseUiSelect },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  emits: ['update-background'],
  data () {
    return {
      loading: true,
      loadingMessage: 'Loader',
      downloadButtonTxt: 'Download PDF',
      responses: [],
      report: null,
      responseScope: 'Alle',
      responseScopes: ['Alle'],
      responseScopesInfoText: 'Du kan oprette nye målinger under "Send spørgeskemaet ud" på forsiden.',
      info: {
        intro: 'Cirklen til højre for hver kategori viser status for besvarelserne af hvert spørgsmål i kategorien.',
        green: 'Besvarelsen bliver grøn, hvis 80 % eller derover har svaret ‘i meget høj grad’ eller ‘i høj grad’. En cirkel med flest grønne besvarelser viser, at I er godt i gang med at lede stærke arbejdsfællesskaber. Fortsæt det gode arbejde!',
        yellow: 'Besvarelsen bliver gul, hvis under 80 % har svaret ‘i meget høj grad’ eller ‘i høj grad’, og under 20 % har svaret ‘i ringe grad ’ eller ‘i meget ringe grad’. En cirkel med flest gule besvarelser viser, at I er godt på vej med at lede arbejdsfællesskabet, men endnu ikke er i mål. Se anbefalinger nedenfor til inspiration.',
        red: 'Besvarelsen bliver rød, hvis 20 % eller derover har svaret ‘i ringe grad’ eller ‘i meget ringe grad’. Der er plads til forbedring. Se anbefalinger nedenfor, som I kan implementere.',
        outtro: 'Det anbefales, at I gentager målingen over tid for at se, om indsatserne har haft den ønskede effekt.'
      },
      infoExpanded: false,
      resultInfo: [
        {
          intro: 'Her var fokus på oplevelsen af en tydelig kerneopgave, da den er omdrejningspunktet i et stærkt arbejdsfællesskab. Uden kerneopgaven er det ikke tydeligt, hvad I skal lykkes med i virksomheden. En af de allervigtigste opgaver er derfor at formulere, formidle og omsætte en tydelig kerneopgave.',
          recommendationsTitle: 'Anbefaling til indsatser fra Core Culture Toolbox, som har fokus på at udvikle og omsætte kerneopgaven, så I opnår et endnu stærkere arbejdsfællesskab:',
          recommendations: [
            { id: '004', title: 'Core Culture Projektramme', url: 'https://www.coreculture.dk/app/effektiv-ledelse/start-core-culture/' },
            { id: '001', title: 'Formuler jeres kerneopgave', url: 'https://www.coreculture.dk/app/tydelig-kerneopgave/formuler-kerneopgaven/' },
            { id: '002', title: 'Kommuniker jeres kerneopgave', url: 'https://www.coreculture.dk/app/tydelig-kerneopgave/kommuniker-kerneopgaven/' },
            { id: '003', title: 'Omsæt jeres kerneopgave', url: 'https://www.coreculture.dk/app/tydelig-kerneopgave/omsaet-kerneopgaven/' }
            /* { id: '005', title: 'Ambassadører', url: 'https://www.coreculture.dk/app/maalrettet-praksis/ambassadoerer/' },
            { id: '006', title: 'Rollebeskrivelser', url: 'https://www.coreculture.dk/app/maalrettet-praksis/rollebeskrivelser/' },
            { id: '007', title: 'Ledelse af Core Culture', url: 'https://www.coreculture.dk/app/effektiv-ledelse/ledelse-af-core-culture/' } */
          ]
        },
        {
          intro: 'Tydelig ledelse af arbejdet er afgørende, når I ønsker at udvikle stærke arbejdsfællesskaber. Det er ledelsens ansvar at skabe en tydelig retning og klare rammer, så arbejdsfællesskabet kan løfte kerneopgaven bedst muligt.',
          recommendationsTitle: 'Anbefaling til indsatser fra Core Culture Toolbox, der styrker ledelsen i udviklingen af et stærkere arbejdsfællesskab:',
          recommendations: [
            { id: '008', title: 'Ledelseskommunikation', url: 'https://www.coreculture.dk/app/effektiv-ledelse/ny-kommunikation-i-hele-ledelsen/' },
            { id: '009', title: 'Ledelsesgrundlag', url: 'https://www.coreculture.dk/app/effektiv-ledelse/nyt-ledelsesgrundlag-i-hele-ledelsen/' },
            { id: '010', title: 'Core Culture Vidensbank', url: 'https://www.coreculture.dk/app/effektiv-ledelse/vidensbank-i-core-culture/' },
            { id: '011', title: '1:1 samtale', url: 'https://www.coreculture.dk/app/maalrettet-praksis/1-1-samtaler/' }
            /* { id: '012', title: 'Mødekultur', url: 'https://www.coreculture.dk/app/maalrettet-praksis/moedekultur/' },
            { id: '013', title: 'Politikker', url: 'https://www.coreculture.dk/app/maalrettet-praksis/politikker/' } */
          ]
        },
        {
          intro: 'Arbejdsfællesskabet skal have tydelige rammer for at sikre, at alle i hverdagen har en målrettet praksis. Derfor skal alle arbejdsgange, procedurer og praksisser i virksomheden underbygge opgaveløsningen, så kerneopgaven bliver løftet bedst muligt.',
          recommendationsTitle: 'Anbefaling til indsatser fra Core Culture Toolbox, som bidrager til tydelige rammer for arbejdet, så medarbejderne målretter deres praksis og opgaveløsning:',
          recommendations: [
            { id: '020', title: 'Politikker', url: 'https://www.coreculture.dk/app/maalrettet-praksis/politikker/' },
            { id: '016', title: 'Fysisk indretning', url: 'https://www.coreculture.dk/app/maalrettet-praksis/fysisk-indretning/' },
            { id: '017', title: 'MUS', url: 'https://www.coreculture.dk/app/maalrettet-praksis/mus/' },
            { id: '018', title: 'Møder', url: 'https://www.coreculture.dk/app/maalrettet-praksis/moedekultur/' }
            // { id: '017', title: 'Onboarding', url: 'https://www.coreculture.dk/app/maalrettet-praksis/onboarding/' },
            // { id: '018', title: 'Rollebeskrivelser', url: 'https://www.coreculture.dk/app/maalrettet-praksis/rollebeskrivelser/' },
            // { id: '019', title: 'Omsæt kerneopgaven', url: 'https://www.coreculture.dk/app/tydelig-kerneopgave/omsaet-kerneopgaven/' },
            // { id: '021', title: 'Start Core Culture', url: 'https://www.coreculture.dk/app/effektiv-ledelse/start-core-culture/' }
          ]
        }
      ],
      coreCultureIconUrl: coreCultureIconUrl
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.user.loggedIn
    },
    hasResults () {
      return ''
    },
    scopedResponses () {
      if (this.responseScope === 'Alle') {
        return this.responses
      } else if (this.responseScope === '1. måling') {
        return this.responses.filter(resp => resp.scope === 'uden-scope')
      } else {
        const allScopes = this.$store.state.survey.data.customScopes
        const scopeId = allScopes[allScopes.findIndex(scope => scope.title === this.responseScope)].id
        return this.responses.filter(resp => resp.scope === scopeId)
      }
    },
    numOfResponses () {
      return this.scopedResponses.length
    }
  },
  created () {
    console.log(this.resultInfo)
  },
  async mounted () {
    this.$emit('update-background', 'rgb(253,252,248)')
    const loggedIn = this.$store.state.user.loggedIn
    await this.$store.dispatch('survey/initSurvey', { surveyId: null, loggedIn: loggedIn })
    const userSurvey = this.$store.state.survey
    if (this.type === 'virksomhed' && loggedIn) {
      const responsesRef = firebase.firestore().collection('surveys').doc(userSurvey.id).collection('responses').where('ownedBy', '==', '')
      const snapshot = await responsesRef.get()
      this.responses = snapshot.docs.map(doc => doc.data()).filter(response => response.ownedBy === '')
      this.initResponseScopes()
    } else if (this.type === 'personlig') {
      if (this.$store.state.response.data && this.$store.state.response.data.completed) {
        this.responses = [this.$store.state.response.data]
      } else {
        const userResponse = await this.$store.dispatch('response/getUserResponse', userSurvey.id)
        this.responses = userResponse ? [userResponse.data()] : []
      }
    } else {
      console.log('no case matches')
    }
    this.report = createSurveyReport(userSurvey.data, this.responses)
    this.loading = false
  },
  methods: {
    delay (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async downloadPdf () {
      console.log(this.scopedResponses)
      if (this.scopedResponses[0]) {
        this.downloadButtonTxt = 'Downloader... (ca. 1 minut)'
        /* this.report.categories.forEach(cat => {
          cat.recommendationsExpanded = true
          cat.questionsExpanded = true
        }) */
        await nextTick()
        await this.delay(500)
        await this.createPdf()
        this.downloadButtonTxt = 'Download som PDF'
      }
    },
    login () {
      this.$router.push('/login')
    },
    setResponseScope (scope) {
      this.responseScope = scope
      this.report = createSurveyReport(this.$store.state.survey.data, this.scopedResponses)
    },
    initResponseScopes () {
      this.responseScopes = []
      // this.responseScopes.push(this.responseScope)
      this.responseScopes.push('1. måling')
      this.$store.state.survey.data.customScopes.forEach(scope => this.responseScopes.push(scope.title))
    },
    getQuestionDescription (id) {
      if (this.$store.state.questions.data && this.$store.state.questions.data[id]) {
        return this.$store.state.questions.data[id].description
      } else {
        return ''
      }
    },
    getLabels (questions) {
      const arr = []
      if (this.$store.state.questions.data) {
        questions.forEach(q => {
          arr.push(this.getQuestionDescription(q.id))
        })
      }
      return arr
    },
    async createPdf () {
      const opt = {
        margin: 20,
        filename: 'coreculture_rapport.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: ['css', 'avoid-all'] }
      }

      const promises = {}
      this.report.categories.forEach((cat, catIndex) => {
        const catEl = document.querySelector('.cat-chart-' + catIndex)
        promises[`cat${catIndex}`] = html2canvas(catEl)
        promises[`cat${catIndex}questions`] = []
        cat.questions.forEach((q, i) => {
          const qEl = document.querySelector(`.cat-${catIndex} .question-chart-${i}`)
          promises[`cat${catIndex}questions`][i] = html2canvas(qEl)
        })
      })
      const chartCanvasses = {}

      chartCanvasses.cat0 = await promises.cat0
      chartCanvasses.cat0questions = await Promise.all(promises.cat0questions)
      chartCanvasses.cat1 = await promises.cat1
      chartCanvasses.cat1questions = await Promise.all(promises.cat1questions)
      chartCanvasses.cat2 = await promises.cat2
      chartCanvasses.cat2questions = await Promise.all(promises.cat2questions)

      const container = document.createElement('div')
      container.appendChild(htmEl('h1', 'Rapport', null))
      const infoBox = htmEl('div', null, 'padding: 16px; background-color: #f1f1f1;')
      infoBox.appendChild(htmEl('h5', 'Sådan læser du rapporten', 'margin-top: 0;'))
      infoBox.appendChild(htmEl('p', this.info.intro, 'font-size: 13px;'))
      const dotStyle = 'display: inline-block; width: 11px; height: 11px; border-radius: 50%; vertical-align: middle; margin-right: 5px;'
      const green = htmEl('p', null, 'font-size: 13px;')
      green.appendChild(htmEl('span', null, dotStyle + 'background-color: #74AC97;'))
      green.appendChild(htmEl('span', this.info.green, 'font-size: 13px;'))
      infoBox.appendChild(green)
      const yellow = htmEl('p', null, 'font-size: 13px;')
      yellow.appendChild(htmEl('span', null, dotStyle + 'background-color: #F5C95F;'))
      yellow.appendChild(htmEl('span', this.info.yellow, 'font-size: 13px;'))
      infoBox.appendChild(yellow)
      const red = htmEl('p', null, 'font-size: 13px;')
      red.appendChild(htmEl('span', null, dotStyle + 'background-color: #DA6457;'))
      red.appendChild(htmEl('span', this.info.red, 'font-size: 13px;'))
      infoBox.appendChild(red)
      infoBox.appendChild(htmEl('p', this.info.outtro, 'font-size: 13px; margin-bottom: 0;'))
      container.appendChild(infoBox)

      this.report.categories.forEach((cat, i) => {
        if (i === 0) {
          container.appendChild(htmEl('h3', cat.title, null))
        } else {
          container.appendChild(htmEl('h3', cat.title, 'page-break-before: always;'))
        }
        const catChart = chartCanvasses[`cat${i}`]
        catChart.style = 'float: right; height: 6rem;'
        container.appendChild(catChart)
        container.appendChild(htmEl('p', cat.description, 'font-size: 1.3rem; page-break-inside: auto;'))
        container.appendChild(htmEl('h4', 'Resultat', null))
        container.appendChild(htmEl('p', this.resultInfo[i].intro, 'font-size: 16px;'))

        /* const dotStyle = 'display: inline-block; width: 14px; height: 14px; border-radius: 50%; vertical-align: middle; margin-right: 5px;'
        const green = htmEl('p', null, 'font-size: 16px;')
        green.appendChild(htmEl('span', null, dotStyle + 'background-color: #74AC97;'))
        green.appendChild(htmEl('span', this.resultInfo[i].green, 'font-size: 16px;'))
        container.appendChild(green)
        const yellow = htmEl('p', null, 'font-size: 16px;')
        yellow.appendChild(htmEl('span', null, dotStyle + 'background-color: #F5C95F;'))
        yellow.appendChild(htmEl('span', this.resultInfo[i].yellow, 'font-size: 16px;'))
        container.appendChild(yellow)
        const red = htmEl('p', null, 'font-size: 16px;')
        red.appendChild(htmEl('span', null, dotStyle + 'background-color: #DA6457;'))
        red.appendChild(htmEl('span', this.resultInfo[i].red, 'font-size: 16px;'))
        container.appendChild(red) */

        container.appendChild(htmEl('h5', this.resultInfo[i].recommendationsTitle, null))
        this.resultInfo[i].recommendations.forEach(recommendation => {
          const recEl = htmEl('div', null,
            'display: inline-flex;' +
            'width: calc(50% - (1rem / 2));' +
            'align-items: flex-start;'
          )
          const img = htmEl('img', null, 'width: 16px; height: 16px; display: inline-block; margin-right: 5px;')
          img.src = this.coreCultureIconUrl
          recEl.appendChild(img)
          const link = htmEl('a', recommendation.title, 'font-size: .7rem; font-weight: 500; margin: 0; display: inline-block; line-height: 16px;')
          link.href = recommendation.url
          recEl.appendChild(link)
          container.appendChild(recEl)
        })
        container.appendChild(htmEl('h4', 'Resultater pr. spørgsmål', null))
        cat.questions.forEach((question, questionIndex) => {
          const description = this.getQuestionDescription(question.id)
          container.appendChild(htmEl('p', questionIndex + 1 + '. ' + description, 'font-size: 16px;'))
          const qChart = chartCanvasses[`cat${i}questions`][questionIndex]
          catChart.style = 'float: right; height: 6rem;'
          const tableContainer = htmEl('div', null, 'display: flex;')
          const tableRow1 = htmEl('tr', null, null)
          tableRow1.appendChild(htmEl('td', 'I meget høj grad', 'font-size: 13px; width: 110px; text-align: center;'))
          tableRow1.appendChild(htmEl('td', 'I høj grad', 'font-size: 13px; width: 110px; text-align: center;'))
          tableRow1.appendChild(htmEl('td', 'I nogen grad', 'font-size: 13px; width: 110px; text-align: center;'))
          tableRow1.appendChild(htmEl('td', 'I ringe grad', 'font-size: 13px; width: 110px; text-align: center;'))
          tableRow1.appendChild(htmEl('td', 'I meget ringe grad', 'font-size: 13px; width: 110px; text-align: center;'))
          const tableRow2 = htmEl('tr', null, null)
          tableRow2.appendChild(htmEl('td', question.results[5] + '%', 'font-size: 16px; width: 110px; text-align: center;'))
          tableRow2.appendChild(htmEl('td', question.results[4] + '%', 'font-size: 16px; width: 110px; text-align: center;'))
          tableRow2.appendChild(htmEl('td', question.results[3] + '%', 'font-size: 16px; width: 110px; text-align: center;'))
          tableRow2.appendChild(htmEl('td', question.results[2] + '%', 'font-size: 16px; width: 110px; text-align: center;'))
          tableRow2.appendChild(htmEl('td', question.results[1] + '%', 'font-size: 16px; width: 110px; text-align: center;'))
          const table = htmEl('table', null, 'flex-grow: 1;')
          table.appendChild(tableRow1)
          table.appendChild(tableRow2)
          tableContainer.appendChild(table)
          tableContainer.appendChild(qChart)
          container.appendChild(tableContainer)
        })
      })
      await html2pdf().set(opt).from(container).toPdf().get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(1)
          pdf.text('Core Culture', 15, 15)
          pdf.text('Side ' + i + ' af ' + totalPages, pdf.internal.pageSize.getWidth() - 30, 15)
          // pdf.text('Side ' + i, (pdf.internal.pageSize.getWidth() / 2), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      }).save()
    }
  }
}
</script>

<style lang="stylus" scoped>
h4
  margin 0 !important
  padding 1.33rem 0 .5rem !important

#content-area
  max-width 800px

section
  margin-bottom 3rem

.cat-header
  display flex

.cat-info
  flex-grow 1

.expander-header:hover
  cursor pointer

.expander-box
  box-sizing border-box
  max-height 0
  overflow: hidden;
  &.expanded
    overflow: visible;
    max-height 2000px

.result-colors p:first-child
    margin-top 1.5rem

.result-colors p:last-of-type
    margin-bottom 1.5rem

.result-colors h5
    margin-bottom 1rem

table
  flex-grow 1

td
  width 35px
  text-align center

.result-info
  margin-left 1rem

.recommendations
  display flex
  flex-wrap wrap
  align-items flex-start
  justify-content space-between

.recommendation
  width calc(50% - (1rem / 2))
  box-sizing border-box
  // box-shadow 0 0 4px rgba(0,0,0,0.2)
  // background-color rgba(42,104,137, .05)
  text-align left
  margin-bottom 1rem

@media screen and (max-width: 480px) {
  .recommendation {
    width: calc(100%);
  }
}
.recommendation header
  display flex
  justify-content flex-start
  align-items center
  padding 0

.recommendation header img
  margin-right 5px

.recommendation header h6
  font-size .7rem
  margin 0

.recommendation p
  margin 0
  padding .5rem

.recommendation footer
  padding .5rem
  display flex
  justify-content center
  font-size 13px

.recommendation footer .tags
  margin-left .2rem

.recommendation footer .tag
  margin-right .5rem

.category-chart
  width: 8rem;
  height: 8rem;
  padding 0rem 0rem 0rem 1rem

.question-results
  margin 0 1rem
  justify-content center
  margin-bottom 2rem

.question-chart
  width 5.5rem
  height 5.5rem
  max-width 88px
  flex-grow 0

span.dot
  display inline-block
  width 10px
  height 10px
  border-radius 50%
  &.green
    background-color #74AC97
  &.red
    background-color #DA6457
  &.yellow
    background-color #F5C95F

.calculation-info
  padding 1px 1rem .4rem
  background #f1f1f1

.next-steps-columns
  display: flex;
  > div:not(:last-child)
    margin-right: 2rem;

@media screen and (max-width: 480px) {
  .next-steps-columns {
    flex-wrap wrap
  }
}
</style>
