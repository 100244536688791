export const createSurveyReport = (surveyData, allResponses) => {
  const survey = surveyData
  const responses = allResponses
  const report = {}
  report.categories = []
  survey.categories.forEach(cat => {
    const categoryObject = {}
    categoryObject.scores = []
    const categoryAnswerCount = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      sum: 0
    }
    const questions = []
    cat.questions.forEach((q, i, arr) => {
      const questionObj = {}
      const questionAnswerCount = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        sum: 0
      }
      // Counting responses in each response category and adding them to category scores and question scores
      responses.forEach(response => {
        const answer = response.answers[q]
        if (response.completed && answer !== null) {
          questionAnswerCount[answer]++
          questionAnswerCount.sum++
          categoryAnswerCount[answer]++
          categoryAnswerCount.sum++
        }
      })
      for (const key in questionAnswerCount) {
        if (key !== 'sum') {
          const num = (questionAnswerCount[key] / questionAnswerCount.sum) * 100
          // const roundedNum = Math.round((num + Number.EPSILON) * 100) / 100
          // const roundedNum = num.toFixed(2)
          const roundedNum = (+(Math.round(num + 'e+0') + 'e-0'))
          questionAnswerCount[key] = roundedNum
        }
      }
      const highScore = questionAnswerCount[5] + questionAnswerCount[4]
      const lowScore = questionAnswerCount[1] + questionAnswerCount[2]
      const score = highScore > 80 ? 4 : (lowScore > 20 ? 2 : 3)
      categoryObject.scores.push(score)
      questionObj.scores = []
      for (let x = 0; x < arr.length; x++) {
        questionObj.scores.push(1)
      }
      questionObj.scores[i] = score
      questionObj.results = questionAnswerCount
      questionObj.id = q
      questions.push(questionObj)
    })
    for (const key in categoryAnswerCount) {
      if (key !== 'sum') {
        const num = (categoryAnswerCount[key] / categoryAnswerCount.sum) * 100
        // const roundedNum = Math.round((num + Number.EPSILON) * 100) / 100
        // const roundedNum = num.toFixed(2)
        const roundedNum = (+(Math.round(num + 'e+0') + 'e-0'))
        categoryAnswerCount[key] = roundedNum
      }
    }
    categoryObject.title = cat.title
    categoryObject.color = cat.color
    categoryObject.description = cat.description
    categoryObject.results = categoryAnswerCount
    categoryObject.questionsExpanded = false
    categoryObject.questions = questions
    categoryObject.recommendationsExpanded = true
    report.categories.push(categoryObject)
  })
  return report
}
